/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #feefe4,
    100 : #fcd7bd,
    200 : #fabc91,
    300 : #f7a164,
    400 : #f68c43,
    500 : #f47822,
    600 : #f3701e,
    700 : #f16519,
    800 : #ef5b14,
    900 : #ec480c,
    A100 : #ffffff,
    A200 : #ffe9e3,
    A400 : #ffc2b0,
    A700 : #ffae96,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-accent: (
    50 : #e3f0f7,
    100 : #b8d9ea,
    200 : #89bfdd,
    300 : #59a5cf,
    400 : #3692c4,
    500 : #127fba,
    600 : #1077b3,
    700 : #0d6cab,
    800 : #0a62a3,
    900 : #054f94,
    A100 : #c0ddff,
    A200 : #8dc2ff,
    A400 : #5aa6ff,
    A700 : #4199ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warn: (
    50 : #fce6e4,
    100 : #f8c0bd,
    200 : #f39791,
    300 : #ee6d64,
    400 : #ea4d43,
    500 : #e62e22,
    600 : #e3291e,
    700 : #df2319,
    800 : #db1d14,
    900 : #d5120c,
    A100 : #ffffff,
    A200 : #ffcecd,
    A400 : #ff9c9a,
    A700 : #ff8380,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

