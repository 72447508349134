/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~material-design-icons/iconfont/material-icons.css';

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import "variables.scss";


.basic-container {
    padding: 5px;
    min-width: 740px;
    overflow: auto;
}

body { 
  font-family: Roboto, Arial, sans-serif;
  margin: 0px 0px 0px 0px;
  height :100%;
 }
 
 